<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<div class="contact">
			<el-row class="map-box">
				<div class="information-imgbox">
					<div class="information-img">
						<el-row class="information">
							<el-row class="information-emailTell">
								<el-row>
									<el-col :span="9">E-mail:</el-col>
									<el-col :span="9" :offset="6">Tell:</el-col>
								</el-row>
								<el-row class="data">
									<el-col :span="9">{{list.email}}</el-col>
									<el-col :span="9" :offset="6">{{list.tell}}</el-col>
								</el-row>
							</el-row>
							<el-row class="information-address">
								<el-row>
									Address:
								</el-row>
								<el-row class="data">
									{{list.adress}}
								</el-row>
							</el-row>
						</el-row>
					</div>
				</div>
			</el-row>
			<!-- 画布 -->
			<div class="coverage">
				<el-image class="coverage-img" src="/home/coverage.png"></el-image>
				<el-image class="coverage-newsimg" src="/index/site.png"></el-image>
			</div>
			
			<div class="write-information">
				<el-row>
					<el-col :span="22" :offset="1">
						<input v-model="form.name" placeholder="NAME" class="write-name" maxlength="50">
					</el-col>
					<el-col :span="22" :offset="1">
						<input v-model="form.phone" placeholder="TELEPHONE" class="write-name" maxlength="20">
					</el-col>
					<el-col :span="22" :offset="1">
						<input v-model="form.email" placeholder="E-MAIL" class="write-name" maxlength="150">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="22" :offset="1">
						<textarea rows="50" cols="3" v-model="form.question" placeholder="YOUR QUESTION" class="write-question" maxlength="1000"></textarea>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="20" :offset="2">
						<button class="submit" @click="submit">SUBMIT</button>
					</el-col>
				</el-row>
			</div>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	import { savaLeave , selectContentListById } from '@/api/index.js'
	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 导航值
				navigationvalue:false,
				
				form:
				{
					name:'',
					phone:'',
					email:'',
					question:''
				},
				list:{}
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveBottomNavigation,
		},
		created()
		{
			this.getList();
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			// 获取数据
			getList()
			{
				selectContentListById(
				{
					chnanelType: 2,
					moduleType: 'MODULE_CONTACT'
				})
				.then((response)=>
				{
					this.list=response.data.data[0];
					console.log(this.list)
				})
			},
			// 提交
			submit()
			{
				if(this.form.name=='' || this.form.phone=='' || this.form.email=='' || this.form.question=='' || this.form.question=='')
				{
					this.$message.error('Please fulfill the above contents accurately and guarantee the reliability of the contents.')
					return false;
				}
				else
				{
					var date=new Date();
					this.form.time=date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
					savaLeave({name:this.form.name,phone:this.form.phone,mail:this.form.email,leaveContent:this.form.question,createTime:this.form.time})
					.then((response)=>
					{
						this.$message.success('Message sent successfully', 'successfully');
						this.form={}
					})
				}
			},
		}
	}
</script>

<style scoped>
	.contact{
		width: 100%;
	}
	.coverage{
		width: 100%;
		height: 0px;
		position: relative;
	}
	.coverage .coverage-img{
		z-index: 9;
		position: absolute;
		width: 100%;
		height: 20px;
		left: 0px;
		bottom: -1px;
	}
	.coverage .coverage-newsimg{
		width: 25px;
		height: 25px;
		position: absolute;
		left: 47.5%;
		bottom: -15px;
		z-index: 99;
	}
	.map-box{
		width: 100%;
		height: 700px;
		background-image: url('/index/movemap.png');
		background-size:100%;
	}
	.map{
		width: 100%;
		height: 700px;
	}
	.information-img{
		width: 645px;
		height: 278px;
		position: relative;
		top: 300px;
		left: 68px;
		color: white;
		background-image: url('/index/informationbox.png');
		background-size:100%;
	}
	.information{
		width: 462px;
		text-align: left;
		left: 116px;
		top: 103px;
		font-size: 18px;
		color: #FFFFFF;
	}
	.write-information{
		width: 100%;
		margin-top: 20px;
	}
	.write-name{
		line-height: 80px;
		padding-left: 3%;
		margin-top: 30px;
		width: 690px;
		height: 80px;
		background: #EEEEEE;
		border: 1px solid #CCCCCC;
		border-radius: 50px;
	}
	.write-question{
		width: 690px;
		height:400px;
		border: solid 2px #DCDCDC;
		background-color: #EFEFEF;
		border-radius: 20px;
		margin-top: 20px;
		padding:20px 10px;
		resize:none;
	}
	.submit{
		width: 600px;
		height: 80px;
		border: none;
		background-color: #FED000;
		line-height: 40px;
		border-radius: 50px;
		margin-top: 50px;
		font-size: 30px;
		color: #505050;
	}
	.data{
		margin-top: 3px;
	}
	.information-emailTell{
		height: 40px;
		line-height: 20px;
	}
	.information-address{
		width: 462px;
		height: 70px;
		font-size: 18px;
		font-family: Arial;
		color: #FFFFFF;
		line-height: 20px;
		margin-top: 39px;
	}
</style>